import { RJSFSchema } from '@rjsf/utils';

import { ImageRatio, MenuConfig, MenuFormatConfig, OnlineOrderingConfig, StyleIntensity } from 'src/apollo/sites';
import { OORestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

const getFormatSchema = (config: MenuFormatConfig | undefined | null): RJSFSchema => {
  return {
    type: 'object',
    properties: {
      template: { enum: ['TopImageV2', 'Classic', 'Condensed', 'ExtraCondensed', 'LeftImageV2'], default: config?.template },
      roundedCorner: { $ref: '#/definitions/StyleIntensity', default: config?.roundedCorner ?? StyleIntensity.None },
      borderColor: { type: ['string', 'null'], default: config?.borderColor },
      borderStroke: { $ref: '#/definitions/StyleIntensity', default: config?.borderStroke ?? StyleIntensity.None },
      dropShadow: { $ref: '#/definitions/StyleIntensity', default: config?.dropShadow ?? StyleIntensity.None },
      imageRatio: { enum: ['Larger', 'Shorter', 'Smaller', 'Taller'], default: config?.imageRatio ?? ImageRatio.Larger },
      columns: { type: 'boolean', default: config?.columns },
      condenseImagelessGroups: { type: 'boolean', default: config?.condenseImagelessGroups },
      expandableSections: { type: 'boolean', default: config?.expandableSections },
      mobileOptimization: { type: 'boolean', default: config?.mobileOptimization },
      placeholderImage: {
        type: 'object',
        properties: {
          generatePlaceholderImage:
          { enum: ['None', 'RepeatedText'], default: config?.placeholderImage?.generatePlaceholderImage },
          placeholderImageText: { type: ['string', 'null'], default: config?.placeholderImage?.placeholderImageText ?? '' }
        }
      },
      hideMenuItemDescription: { type: 'boolean', default: config?.hideMenuItemDescription },
      hideMenuNav: { type: 'boolean', default: config?.hideMenuNav },
      hideSubMenuNav: { type: 'boolean', default: config?.hideSubMenuNav },
      menuSearchMode: { enum: ['HIDDEN', 'VISIBLE'], default: config?.menuSearchMode }
    }
  };
};

export const getOrderPageConfigSchema = (config: OnlineOrderingConfig | undefined | null): RJSFSchema => {
  return {
    title: 'OO Config',
    type: 'object',
    definitions: { StyleIntensity: { enum: ['None', 'Exaggerated', 'Subtle'] } },
    properties: {
      format: getFormatSchema(config?.format),
      colors: {
        type: 'object',
        properties: {
          background: { type: ['string', 'null'], default: config?.colors?.background },
          primaryText: { type: ['string', 'null'], default: config?.colors?.primaryText },
          secondaryText: { type: ['string', 'null'], default: config?.colors?.secondaryText }
        }
      },
      defaultDiningBehavior: { enum: ['delivery', 'takeout'], default: config?.defaultDiningBehavior },
      hideOutOfStockItems: { type: 'boolean', default: config?.hideOutOfStockItems },
      popularItemsDisabled: { type: 'boolean', default: config?.popularItemsDisabled }
    }
  };
};

export const getMenuPageConfigSchema = (config: MenuConfig | undefined | null): RJSFSchema => {
  return {
    title: 'Menu Config',
    type: 'object',
    definitions: { StyleIntensity: { enum: ['None', 'Exaggerated', 'Subtle'] } },
    properties: {
      format: getFormatSchema(config?.format),
      colors: {
        type: 'object',
        properties: {
          background: { type: ['string', 'null'], default: config?.colors?.background },
          primaryText: { type: ['string', 'null'], default: config?.colors?.primaryText },
          secondaryText: { type: ['string', 'null'], default: config?.colors?.secondaryText }
        }
      },
      hideOutOfStockItems: { type: 'boolean', default: config?.hideOutOfStockItems },
      popularItemsDisabled: { type: 'boolean', default: config?.popularItemsDisabled }
    }
  };
};

export const getOORestaurantOverrideSchema = (config: OORestaurant | undefined | null): RJSFSchema => {
  return {
    title: 'OO Restaurant Config',
    type: 'object',
    properties: {
      onlineOrderingEnabled: { type: 'boolean', default: config?.onlineOrderingEnabled },
      minimumTakeoutTime: { type: 'number', default: config?.minimumTakeoutTime },
      minimumDeliveryTime: { type: 'number', default: config?.minimumDeliveryTime },
      creditCardConfig: {
        type: 'object', properties: {
          amexAccepted: { type: 'boolean', default: config?.creditCardConfig.amexAccepted },
          tipEnabled: { type: 'boolean', default: config?.creditCardConfig.tipEnabled }
        }
      },
      giftCardConfig: {
        type: 'object', properties: {
          hasGiftCardsExtension: { type: 'boolean', default: config?.giftCardConfig?.hasGiftCardsExtension },
          redemptionAllowed: { type: 'boolean', default: config?.giftCardConfig?.redemptionAllowed },
          supportVerificationCode: { type: 'boolean', default: config?.giftCardConfig?.supportVerificationCode }
        }
      },
      specialRequestsConfig: {
        type: 'object', properties: {
          enabled: { type: 'boolean', default: config?.specialRequestsConfig.enabled },
          placeholderMessage: { type: ['string', 'null'], default: config?.specialRequestsConfig.placeholderMessage }
        }
      },
      spotlightConfig: {
        type: 'object', properties: {
          headerText: { type: ['string', 'null'], default: config?.spotlightConfig?.headerText },
          bodyText: { type: ['string', 'null'], default: config?.spotlightConfig?.bodyText }
        }
      }
    }
  };
};

export const getMetaOverrideSchema = (config: any | undefined | null): RJSFSchema => {
  return {
    title: 'Meta',
    type: 'object',
    properties: {
      backgroundColor: { type: ['string', 'null'], default: config?.backgroundColor },
      primaryColor: { type: ['string', 'null'], default: config?.primaryColor },
      textColor: { type: ['string', 'null'], default: config?.textColor },
      secondaryTextColor: { type: ['string', 'null'], default: config?.secondaryTextcolor },
      title: { type: ['string', 'null'], default: config?.title }
    }
  };
};
