// For polyfilling:
import 'core-js';
import 'regenerator-runtime/runtime.js';
import React, { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { hydrateRoot } from 'react-dom/client';

import { createClient } from 'src/apollo/createClient';

import ErrorBoundary from 'shared/components/common/error_boundary/ErrorBoundary';

import ClientApp from 'public/components/app/ClientApp';

import { sentry, resources, ldClientId } from 'config';

import './index.scss';

// Kick off client-side monitoring
if(sentry.dsn && typeof window !== 'undefined') {
  Sentry.init({
    dsn: sentry.dsn,
    environment: sentry.env,
    // eslint-disable-next-line no-undef
    release: `toast-sites-web@${VERSION}`,
    // dont log errors from PetalBot
    beforeSend: ev => ev?.request?.headers['User-Agent'].includes('PetalBot') ? null : ev
  });
}

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientId,
    context: { key: 'sites-web' },
    options: { streaming: false }
  });

  const component =
    <StrictMode>
      <ErrorBoundary>
        <LDProvider>
          <ApolloProvider client={createClient(resources.apiEndpoint, window.__APOLLO_STATE__, false, { enabled: true }, undefined, false, false, resources.clientQueryTimeoutMs)}>
            <HelmetProvider>
              <ClientApp />
            </HelmetProvider>
          </ApolloProvider>
        </LDProvider>
      </ErrorBoundary>
    </StrictMode>;


  const container = document.getElementById('content');

  hydrateRoot(container, component);
};

renderApp();
