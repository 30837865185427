import React, { useMemo } from 'react';

import Form, { IChangeEvent } from '@rjsf/core';
import { UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { useDebug } from 'src/shared/components/common/debug/DebugContext';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { ExpandableForm } from 'public/components/default_template/debug/DebugPanel';

import { getMetaOverrideSchema } from './DebugSchemas';


export const MetaDebugForm = () => {
  const { setMetaOverride } = useDebug();
  const { restaurant } = useRestaurant();

  const uiSchema: UiSchema = {
    'ui:classNames': 'form',
    backgroundColor: { 'ui:widget': 'color' },
    primaryColor: { 'ui:widget': 'color' },
    textColor: { 'ui:widget': 'color' },
    secondaryTextColor: { 'ui:widget': 'color' }
  };
  const onSubmit = (data: IChangeEvent) => {
    const formData = data.formData;
    setMetaOverride(formData);
  };
  const schema = useMemo(() => {
    return getMetaOverrideSchema(restaurant.meta);
  }, [restaurant.meta]);
  return (
    <ExpandableForm title={schema.title}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={onSubmit} />
    </ExpandableForm>
  );
};
