import React, { useMemo } from 'react';

import Form, { IChangeEvent } from '@rjsf/core';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { useDebug } from 'src/shared/components/common/debug/DebugContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { ExpandableForm } from 'public/components/default_template/debug/DebugPanel';

import { getOORestaurantOverrideSchema } from './DebugSchemas';


export const OORestaurantConfigForm = () => {
  const { setOORestaurantOverride } = useDebug();
  const { ooRestaurant } = useRestaurant();
  const uiSchema: UiSchema = { 'ui:classNames': 'form' };
  const onSubmit = (data: IChangeEvent<any, RJSFSchema, any>) => {
    const formData = data.formData;
    setOORestaurantOverride({ ...formData });
  };
  const schema = useMemo(() => {
    return getOORestaurantOverrideSchema(ooRestaurant);
  }, [ooRestaurant]);
  return (
    <ExpandableForm title={schema.title ?? 'Config'}>
      <Form schema={schema} uiSchema={uiSchema} validator={validator} onSubmit={onSubmit} />
    </ExpandableForm>
  );
};
