import React from 'react';
import { withRouter } from 'react-router';

import { ApolloProvider } from '@apollo/client';

import { createClient } from 'src/apollo/createClient';
import { RequestContextProps } from 'src/lib/js/context';

import { Modal, ModalOverlay } from 'shared/components/common/modal';

import { resources } from 'config';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  withTransitions?: boolean;
  modalContent?: JSX.Element;
} & RequestContextProps;


export const SecondaryModal = (props: Props) => {
  return (
    <ApolloProvider client={props.staticContext?.req.serverClient || createClient(resources.apiEndpoint, undefined, false, { enabled: true }, undefined, false, false, resources.clientQueryTimeoutMs)}>
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay fadeIn fadeOut />
        {props.modalContent}
      </Modal>
    </ApolloProvider>
  );
};

export default withRouter<Props, React.ComponentType<Props>>(SecondaryModal);
