const config = {};

// Env
config.environment = 'staging';
config.apiVersion = 1;

// Company name
config.companyName = 'Toast';

// Trusted hostname
config.trustedHosts = ['preprod.eng.toasttab.com', 'preprod.nv5.toast.ventures'];

config.serviceName = 'sites-web';

config.giaAuth = false;
config.spiEnabled = true;

config.reCaptchaEnterprise = {};
config.reCaptchaEnterprise.siteKey = '6LennFcmAAAAAGln3bxVtS875kNG9wcIRB4agam2';

// Configuration namespaces
config.server = {};
config.resources = {};
config.datadog = {};
config.sentry = {};
config.owg = {};
config.sift = {};
config.toast = {};

config.toast.clientId = 'encrypted:preproduction/oauth/sites-web:client_id';
config.toast.clientSecret = 'encrypted:preproduction/oauth/sites-web:client_secret';

config.fullstoryOrgId = null;
config.gMapsAPIKey = 'AIzaSyDsJlw0WSpry4Kig6-1LfjR0vo89NYZudM';
config.gPlacesAPIKey = 'AIzaSyDx-1bQhTqW3DUEPNMTk-yegz1c9YQdzUs';
config.ldClientId = '59f23d33a3862f0accd10d65';
config.ldSdkKey = 'sdk-5454caf5-db95-4f94-99b2-1a9f6649f946';
config.toastDopplerSecret = 'encrypted:external/preproduction/sites-web-doppler-token';
config.amplitudeApiKey = 'a1e616beee6b9e6a276cec63168ca837';
config.paypalClientId = 'AYBp1wvkNn0qjfr4JYHbZ0IPxVVc-dkwj-23SgnAGZ9aan6zDvPreSoK7ew5Zj9NtyPFzFOBKe0t0Qs0';
config.ipapikey = 'c053e8b85c68fb776fa75d5f1855bd93';
config.giftCardRecaptchaSiteKey = '6LdD_fcpAAAAAGwSSfEv4YjATtIlX4Y18lAfMS_n';

// Resources configuration
config.resources.serverQueryTimeoutMs = 10_000;
config.resources.clientQueryTimeoutMs = 30_000;
config.resources.apiHost = typeof window === 'undefined' ? 'http://localhost:36867' : 'https://ws-preprod-api.eng.toasttab.com';
config.resources.mapEndpoint = 'https://ws-preprod-api.eng.toasttab.com/sites-api/v1/api';
config.resources.apiEndpoint = `${config.resources.apiHost}/sites-api/v1/api`;
config.resources.federatedProxyHost = `${config.resources.apiHost}/do-federated-gateway/v1/${typeof window === 'undefined' ? 'internal/' : ''}graphql`;
config.resources.webpackDevServer = false;
config.resources.publicAssetURL = 'https://d1knuqod36pnys.cloudfront.net/';
config.resources.imagePrefix = 'https://d3gg8e2riix3j.cloudfront.net/';
config.resources.publicAppURL = `${config.resources.publicAssetURL}app`;
config.resources.ooAssetURL = 'https://s3.amazonaws.com/toasttab/'; // Preprod image urls are broken, and Cloudfront hostname substitution is needed
config.resources.tlAssetUrl = 'https://s3.amazonaws.com/toasttab/';
config.resources.checkoutHost = 'https://preprod.eng.toasttab.com/';
config.resources.toastLocalHost = 'preprod.eng.toasttab.com';
config.resources.toastOrderHost = 'order-pre.toasttab.com';
config.resources.sitesHost = 'https://sites.toasttab/';
config.resources.schemaHostBlocklist = [config.resources.toastLocalHost];
config.resources.exposeSitemap = false;
config.resources.sitemapGenerationBatchSize = 10;
config.resources.toastwebBaseUri = 'https://preprod.eng.toasttab.com/';
config.resources.s3Bucket = 'toast-sites-resources-preprod';
config.resources.adyenURL = 'https://*.adyen.com';
config.resources.semiPaymentGatewayURL = 'https://ws-preprod-api.eng.toasttab.com/semi-payments-gateway';
config.resources.googlePayURL = 'https://pay.google.com/';
config.resources.googleURL = 'https://google.com/';
config.resources.spiURL = 'https://payments.preprod.eng.toasttab.com/assets/loader.js';
// Overrides the default config to disable Google Pay
config.resources.paymentMethodConfigId = '59af32eb-a319-4f59-9829-a83141366120';

config.toastApi = {
  apiRoot: config.resources.apiHost,
  clientId: process.env.TOAST_CLIENT_ID || '',
  clientSecret: process.env.TOAST_CLIENT_SECRET || ''
};

// Server configuration
config.server.protocol = 'https';
config.server.httpPort = 8443;
config.server.webpackDevServerPort = 8000;
config.server.host = 'ws-preprod-api.eng.toasttab.com';
config.server.altHosts = [];
config.server.fullHost = config.server.host;
config.server.allowableResourceHosts = {};

config.resources.sitesHost = `${config.server.protocol}://${config.server.host}`;

config.server.allowableResourceHosts.script = [
  'https://browser.sentry-cdn.com',
  'https://fonts.googleapis.com',
  'https://fonts.gstatic.com',
  'https://maps.googleapis.com',
  'https://www.googletagmanager.com',
  'https://edge.fullstory.com',
  'https://www.google.com/recaptcha/',
  'https://www.gstatic.com/recaptcha/',
  'https://*.paypal.com/',
  'https://cdn.userway.org/',
  'https://api.userway.org/',
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/',
  'https://cdn.sift.com/s.js',
  'https://cdn.cookielaw.org/'
];
config.server.allowableResourceHosts.pixels = [
  'https://*.paypal.com/',
  'https://payments.preprod.eng.toasttab.com',
  'https://payments.toasttab.com'
];
config.server.allowableResourceHosts.connect = [
  'https://www.google.com',
  'https://maps.googleapis.com',
  'https://www.google-analytics.com',
  'https://rs.fullstory.com',
  'https://events.launchdarkly.com',
  'https://app.launchdarkly.com',
  'https://clientstream.launchdarkly.com',
  'https://api2.amplitude.com/2/httpapi',
  'https://api.twilio.com/',
  'https://*.paypal.com/',
  'https://cdn.userway.org/',
  'https://api.userway.org/',
  'https://api.ipapi.com/',
  'https://cdn.cookielaw.org/',
  'https://geolocation.onetrust.com/',
  'https://privacyportal-de.onetrust.com/'
];
config.server.allowableResourceHosts.style = [
  'https://unpkg.com',
  'https://fonts.googleapis.com',
  'https://use.typekit.net',
  'https://p.typekit.net',
  'https://*.paypal.com/',
  'https://cdn.userway.org/',
  'https://api.userway.org/'
];
config.server.allowableResourceHosts.frameSrc = [
  'https://cdn.userway.org/',
  'https://business.untappd.com'
];

// Sentry
config.sentry.host = 'o37442.ingest.sentry.io';
config.sentry.dsn = `https://6d67e6de629842998b120b40164bd25b@${config.sentry.host}/6180756`;
config.sentry.env = 'staging';

// Order with Google Redirects
config.owg.partnerId = '20001125';
config.owg.ooPartnerId = '20003218';
config.owg.conversionHost = 'https://www.google.com/maps/conversion/debug/collect';

// Toast DataDog config
config.datadog.host = process.env.DATADOG_HOST || '';
config.datadog.port = 8125;
config.datadog.globalTags = [
  'toast_service_name:sites-web', `toast_service_revision:${process.env.toast_service_revision}`, 'toast_environment:preproduction', `toast_deployment:${process.env.toast_deployment}`
];
config.datadog.rum = {
  enabled: true,
  clientToken: 'pub42715a09f9a12b1dd63162ff54b9b2b3',
  applicationId: 'b8973e2d-9c80-4e55-93a3-3c9e994702ea',
  samplingRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  host: ['https://browser-intake-datadoghq.com', 'www.datadoghq-browser-agent.com'],
  version: `${process.env.toast_service_revision}`
};

config.oneTrust = { dataDomainScript: '27b2ab47-55c6-4f95-8752-c3c29f5cc956-test' };
config.sift.beaconKey = 'cc9028540f';

// Cache Control
config.cache = {
  cacheAge: 0, // Intermediate cache control
  browserAge: 0, // Browser cache control
  browserExpiresMinutes: 0 // expiration management
};

config.enableCompression = false;

module.exports = config;
