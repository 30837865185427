import React, { useMemo } from 'react';

import Form, { IChangeEvent } from '@rjsf/core';
import { UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { MenuConfig, OnlineOrderingConfig } from 'src/apollo/sites';
import { useDebug } from 'src/shared/components/common/debug/DebugContext';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { ExpandableForm } from 'public/components/default_template/debug/DebugPanel';

import { getMenuPageConfigSchema, getOrderPageConfigSchema } from './DebugSchemas';


export const MenuConfigDebugForm = ({ menuPage }: { menuPage?: boolean }) => {
  const { setOrderPageConfigOverride, setMenuPageConfigOverride } = useDebug();
  const { restaurant } = useRestaurant();

  const uiSchema: UiSchema = {
    'ui:classNames': 'form',
    format: { borderColor: { 'ui:widget': 'color' } },
    colors: {
      background: { 'ui:widget': 'color' },
      primaryText: { 'ui:widget': 'color' },
      secondaryText: { 'ui:widget': 'color' }
    }
  };
  const onSubmit = (data: IChangeEvent) => {
    const formData = data.formData;
    if(menuPage) {
      setMenuPageConfigOverride(formData);
    } else {
      setOrderPageConfigOverride(formData);
    }
  };
  const config = useMemo(
    () =>
      menuPage
        ? restaurant?.config?.menuConfig
        : restaurant.config.ooConfig,
    [
      menuPage,
      restaurant?.config?.menuConfig,
      restaurant.config.ooConfig
    ]
  );
  const schema = useMemo(() => {
    return menuPage ? getMenuPageConfigSchema(config as MenuConfig) : getOrderPageConfigSchema(config as OnlineOrderingConfig);
  }, [config, menuPage]);
  return (
    <ExpandableForm title={schema.title ?? 'Config'}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={onSubmit} />
    </ExpandableForm>
  );
};
